/* .App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */
.switch {
  position: relative;
  display: inline-block;
  width: 30px;
  height: 16px;
 
}

.switch input { 
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 32px;  /* Adjusted width */
  height: 16px;  /* Adjusted height */
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 12px;  /* Adjusted height */
  width: 12px;  /* Adjusted width */
  left: 2px;
  bottom: 2px;
  background-color: black;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked + .slider {
  background-color: #1ED760;
}

input:focus + .slider {
  box-shadow: 0 0 1px #1ED760;
}

input:checked + .slider:before {
  -webkit-transform: translateX(16px);  /* Adjusted translation */
  -ms-transform: translateX(16px);
  transform: translateX(16px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}


.next-button {
  opacity: 1;
}

.next-button-disabled {
  opacity: 0.5;
}

.prev-button {
  opacity: 1;
}
/* .bg{
  background-image: url(/spotify-app/frontend/src/assets/18165.jpg);
} */


.prev-button-disabled {
  opacity: 0.5;
}
.align-top {
  vertical-align: top;
  /* height: 10px !important; */
}
.fixed-height-row {
  max-height: 10px !important; /* Adjust the max-height as needed */
  /* overflow-y: auto;  */
  /* border: 2px solid red;*/

}
.custom-scrollbar {
  width: 100%;
  height: 100vh; /* Set the desired height */
  overflow-y: auto; /* Enable vertical scrolling */
}
.table-bottom{
  height: 750px !important;
}

.loader {
  border: 4px solid rgba(255, 255, 255, 0.3);
  border-top: 4px solid #3498db;
  border-radius: 50%;
  width: 20px;
  height: 16px;
  animation: spin 2s linear infinite;
}
.nav{
 background:  radial-gradient(100% 100% at 50% 0%,#fbb115 0%,#e87447 100%);
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
.svg-img{
  margin-right: 300px;
}
.custom-scrollbar::-webkit-scrollbar {
 
  width: 12px; /* Set the desired width for the scrollbar */
}
.bg{
  background:#1ED760;
}
.btn{
  background:#1ED760;
}
.custom-scrollbar::-webkit-scrollbar-thumb {
  background-color: #2c2c2c; /* Customize the thumb color */
  border-radius: 6px; /* Set the border radius for the thumb */
}

.custom-scrollbar::-webkit-scrollbar-track {
  background-color: black; /* Customize the track color */
}
