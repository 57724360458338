
@tailwind base;
@tailwind components;
@tailwind utilities;
@font-face {
    font-family: 'circular';
    src: url('./fonts/CircularStd-Black.otf');
  
  }
  @font-face {
    font-family: 'circularlight';
    src: url('./fonts/light.ttf');
  
  }